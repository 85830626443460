export class BrCreateMockerRequest {
  MessageType: string = "BOOKINGREQUESTCREATE"
  Parameters: BookingRequestParameters
}

export class BookingRequestParameters {
  MessageBusinessChannel: string
  MessageSenderPartyId: string
  MessageReceiverPartyId: string
  ShipmentsDeclaredShipmentValueAmount: number
  ShipmentsIsoCurrencyCode: string
  ShipmentsPlannedShipmentDate: string
  ShipmentsPartyShipToId: string
  ShipmentsPartyShipFromId: string
  ShipFromPartyContact: string
  ShipFromLocationId: string
  ShipToLocationId: string
  LocationCountryCode: string
  Shipmentlines: BookingRequestShipmentline[]
}

export class BookingRequestShipmentline {
  Orders: BookingRequestOrder[]
  ShipmentLinesPalletOrCarton: string
  ShipmentLinesPalletId: string
  WeightUnitOfMeasureName: string
  PalletOrCartonQuantity: number
  ShipmentLinesCartonId: string
}

export class BookingRequestOrder {
  OrderId: string
  OrderLineNumber: string
  ReturnPolicyId: string
  POQuantity: number
  VendorItemSku: string
  ComponentPartIdentifier: string
  ItemSku: string
  HazardousMaterialIndicator: boolean
  ItemsStackableIndicator: boolean
  HazardSeverityCategoryName: string
}
