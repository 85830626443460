import { Component, OnInit, Input, ViewChild, OnDestroy, TemplateRef, } from '@angular/core';
import { UatAvailabilityService } from '../../service/uatavailability.service';
import { MasterDataService } from '../../service/masterdata.service';
import { MsalService } from '@azure/msal-angular';
import { ConfigService } from '../../service/config.service';
import * as log from '../../AppInsightsLogger';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { ManageScenariosService } from '../../service/manage-scenarios.service';
import { TestCaseProjectService } from '../../service/test-case-project.service';
import { Status } from '../status/status.component';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { Router } from '@angular/router';
import { ProductRequest, ProductRequestList } from 'src/app/model/productrequest.model';
import { SharedOutputRequest, SNValidationRequest, TestCaseInstance, TestCaseInstanceRequest, TestPlanExcelUploadInput, TestPlanInstance, TestPlanInstanceRequest, TestPlanPreStepInstance } from 'src/app/model/project-view.model';
import { TestRunRetriggerInput, UpdateScenarioStepInput } from 'src/app/model/manage-testruns.model';
import { TestCasesDataJson, TestCaseDataArray } from 'src/app/model/testCaseDataJson.model';
import { HttpClient } from '@angular/common/http';
import { Subject } from 'rxjs';
import { SimpleGridComponent } from '../test-grid/simple-grid/simple-grid.component';
import { saveAs } from 'file-saver';
import { ExcelFileService } from '../../service/excelFile.service';
import { OrgViewService } from 'src/app/service/org-view.service';
import * as XLSX from 'xlsx';
import { TestRunBulkUploadInput } from 'src/app/model/execute-testruns.model';
import { DescriptionService } from '../../service/description.service';


@Component({
    selector: 'app-test-case-view',
    templateUrl: './test-case-view.component.html',
    styleUrls: ['./test-case-view.component.css']
})
export class TestCaseViewComponent implements OnInit, OnDestroy {

  @ViewChild(SimpleGridComponent) table_data: SimpleGridComponent;
  public simpleGrid: SimpleGridComponent;
  @ViewChild('inputFileModal', { static: true, read: TemplateRef })
  inputFileModal: TemplateRef<any>;
  @ViewChild('saveModal', { static: true, read: TemplateRef })
  saveModal: TemplateRef<any>;
   

    testPlanStatusDisabled = false;
    userName: string;
    userEmail: string;
    validationStatus: string = "";

    private logger: log.AppInsightsLogger;
    private testPlanInstance: any;
    private testPlanName: string = "";
    private testPlanStatus: string = "";
    private testPlanCreatedOn: string = "";
    private testPlanOwner: string = "";
    private testPlanProjectName: string = "";
    private testPlanStartOn: string = "";
    private testPlanCompletedBy: string = "";
    private testPlanLastModified: string = "";
    private testPlanInstanceId: string = "";
    private testPlanId: number;
    country: string = "";
    partnerName: string = "";
    stateCode:string = "";
    isPartnerSet: boolean = false;
    isCountrySet: boolean = false;
    isStateSet: boolean = false;
    //variables for uploading SKUS
    selectedFile;
    lines: string[] = [];
    linesR: [];

    grid_use_levels: boolean = false;
    preStepsData: any[] = [];
    preStepsSchema: any = [];
    testCaseData: any[] = [];
    testCaseSchema: any = [];
    testCaseGridSchema: any = [];


    isLoading: boolean = true;
    selectedTestCases: any[] = [];
    projectID: any;
    testPlan: any;
    executionResult: any;
    excel: Blob;
    detailLinkMap = new Map<string, any>();
    destroy$ = new Subject();
    buttonText = "Execute";
    pathReturned: any;
    emailBody: string;
    assignSkuResponse: any;
    saveButtonDisabled: boolean = false;
    testCasesDataJson = new TestCasesDataJson();
    downloadTestCaseJson: any[] = [];
    private dateString: string;
    excelData: any;
    organizationId: string;
    glossaryList: any[];
    is500error: boolean = false;
    testCaseDescription: string;
    testPlanDescription: string;
    prerequisitesDescription: string;    

    // fields that are determined by UI config.
    showCountry: any;
    showState: any;
    showPartner: any;
    showTriggerValidation: any;
    showRerun: any;
    showTriggerNextStep: any;
    currentStepRank: any;
    showEmailExcelButton: any;
    selectedFileName: string = '';
    modal: any;
    saveSuccess: boolean=false;



  constructor(private excelService: ExcelFileService,
        private ngxLoader: NgxUiLoaderService,
        private msalService: MsalService,
        private configService: ConfigService,
        private uatDataService: UatAvailabilityService,
        private masterDataService: MasterDataService,
        private manageTestRunsService: ManageScenariosService,
        private testCaseProjectService: TestCaseProjectService,
        private modalService: NgbModal,
        private activatedRoute: ActivatedRoute,
        private router: Router,
        private httpClient: HttpClient,
        private orgService: OrgViewService,
        private descriptionService: DescriptionService) {
        this.logger = new log.AppInsightsLogger(configService, msalService);
        this.userName = this.msalService.instance.getActiveAccount().name;
        this.userEmail = this.msalService.instance.getActiveAccount().username;


    }

    ngOnInit() {        
        this.loadGlossary();
        this.activatedRoute.paramMap.subscribe((params: ParamMap) => {
            this.processActiveRoute();
        });
    }

    processActiveRoute() {
        this.organizationId = window.sessionStorage.getItem("orgSelected");
        let organizationId = this.activatedRoute.snapshot.params['org']|| "";
        if(organizationId != null && this.organizationId != organizationId){
            this.organizationId = organizationId;
            window.sessionStorage.setItem("orgSelected", this.organizationId);
        }
        this.projectID = parseInt(this.activatedRoute.snapshot.params['projectId']);
        this.testPlanId = parseInt(this.activatedRoute.snapshot.params['testPlanId']);
        this.testPlanInstanceId = this.activatedRoute.snapshot.params['testPlanInstanceId'];

        //add this to configure the ui config fields
        this.configureTestPlanLayout();
        this.loadDataCore(this.testPlanInstanceId);
    }
    
    configureTestPlanLayout() {
        this.httpClient
          .get<any[]>("./assets/configuration/testPlanConfig.json")
          .subscribe((response) => {
            let layout = response.find(
              (config) => config["testPlanId"] == this.testPlanId
            );
            if (layout != null) {
              this.showCountry = layout.topPanel.showCountry;
              this.showPartner = layout.topPanel.showPartner;
              this.showState = layout.topPanel.showState;
              this.showTriggerValidation = layout.buttons.triggerValidation;
              this.showRerun = layout.buttons.rerun;
              this.showTriggerNextStep = layout.buttons.triggerNextStep;
              this.showEmailExcelButton = layout.buttons.emailExcel;
            }
          });
    }


    loadGlossary() {
        this.descriptionService.getGlossary().subscribe((response: any[]) => {
            if (response != null) {
                this.glossaryList = response;
            }
        
            for (var i = 0; i < this.glossaryList.length; i++) {
                if (this.glossaryList[i].name == "Test Case") {
                    this.testCaseDescription = this.glossaryList[i].description;
                } else if (this.glossaryList[i].name == "Test Plan") {
                    this.testPlanDescription = this.glossaryList[i].description;
                } else if (this.glossaryList[i].name == "Prerequisites") {
                    this.prerequisitesDescription = this.glossaryList[i].description;
                }
            }
        }, (error: any) => {
            if (error.status == 500) {
                this.is500error = true;
            }
            this.logger.trackTrace(`In Project, load Glossary Details Error`);
            this.logger.trackException(error);
            this.ngxLoader.stop();
        });
    }

    ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();
    }


    loadDataCore(testPlanInstanceId: string) {
        //when creating a new test plan instance 
        if (testPlanInstanceId == "newTestPlanInstance") {
            this.isLoading = true;
            this.saveSuccess= false;
            //create new test plan instance request with test case instances//
            this.testPlanInstance = {};

            //TODO: <rozerinakkus> <02-02-2022> Test Plan Status Logc status should be defined, e.g. if validation triggered in progress etc. 
            this.testPlanStatus = Status.NOT_STARTED;
            
            this.setTestCaseSchema();
            this.getTestCasesFromProject();
            //TODO:PREREQ STEPS 
            this.setCustomStepsSchema();
            this.createTestPlanPreSteps();
            
        }

        //if going to a already existing test plan instance
        else {
            this.isLoading = true;

            this.testCaseProjectService.getTestPlanInstance(this.testPlanInstanceId).subscribe((response: any) => {
            this.testPlanInstance = response;
            this.testPlanName = response.testPlanName;
            //TODO: <rozerinakkus> <02-02-2022> Test Plan Status Logic status should be defined, e.g. if validation triggered in progress etc. 
            this.testPlanStatus = response.status;
            this.testPlanCreatedOn = response.createDate;
            this.testPlanOwner = response.owner;
            this.testPlanStartOn = response.startDate;
            this.testPlanCompletedBy = response.createDate;
            this.testPlanLastModified = response.modifiedDate;
            //this.testPlanInstanceId = response.testPlanInstanceID;
            this.testCaseProjectService
              .getProjectTestCaseView(this.projectID)
              .subscribe(
                (response: any) => {
                  if (response != null)
                    this.testPlanProjectName = response[0].projectName;
                },
                (error: any) => {
                  this.logger.trackTrace(
                    `Error in Get Project in Test Plan Instance View: ${error}`
                  );
                  this.logger.trackException(error);
                }
              );
            
            this.testPlanId = response.testPlanID;
            if(response.testCaseInstances.length > 0 && response.testCaseInstances[0].correlationId != ""){ 
                this.testCaseData = response.testCaseInstances;
            }
            else{
                this.testCaseData = [];
            }
            //this.testCaseData = response.testCaseInstances;
            this.excelData = this.testCaseData;
            this.createExcelData(this.excelData);

                //TODO: add country and partner
                try {
                    this.country = response.countryCode || "";
                    this.partnerName = response.customerName || "";
                    this.stateCode = response.stateCode || "";

                    if (this.partnerName != undefined && this.partnerName != "") {
                        this.isPartnerSet = true;
                        this.updateTestCasePartnerName();
                    }
                    if (this.country != undefined && this.country != "") {
                        this.isCountrySet = true;
                    }

                    if (this.stateCode != undefined && this.stateCode != "") {
                        this.isStateSet= true;
                    }

                    if(this.testPlanId != 1){
                        this.testCaseData = this.testCaseData.filter(x=>x.testPlanPreStepInstanceId == null || x.testPlanPreStepInstanceId == undefined);
                        for(var i = 0; i<this.testCaseData.length; i++){
                            
                            const currentStep:string = this.testCaseData[i].currentStep;
                            if(currentStep.includes("Manual")){
                                if(this.testCaseData[i].currentStepStatus == "Complete"){
                                    this.testCaseData[i].status = "Complete";
                                }
                                else{
                                   this.testCaseData[i].status = "Pending User Action";
                                }
                            }
                            else {
                                this.testCaseData[i].status = this.testCaseData[i].currentStepStatus;
                            }
    
                        }
                    }
                } catch (Error) {
                    //swallow error 
                }
                this.setTestCaseSchema();
                this.setCustomStepsSchema();
                //this.getTestPlanPreSteps();
                this.refreshPreStepsData();
                this.fetchDetailLink().then(value => {
                }).catch((err) => {
                    this.refreshPreStepsData();
                    this.isLoading = false;                    
                });
            });

            if (testPlanInstanceId == "newTestPlanInstance") {
                this.saveSuccess = false;
            }
            else{
                this.saveSuccess = true;
            }
        }
  }

 
  //extracting data for simpleGrid. 
  simpleGridData(data: any) {
    this.excelData = data;
    this.createExcelData(this.excelData);

  }

  private createExcelData(data: any) {
    this.downloadTestCaseJson = [];
    this.testCasesDataJson.testCaseDataArray = [];
    data.forEach(element => {
      let testCase = new TestCaseDataArray();
      testCase.Id = element.testCaseIdentifier;
      testCase.Name = element.testCaseName;
      testCase.Status = element.status;
      testCase.LastExecuted = element.createDate;
      this.testCasesDataJson.testCaseDataArray.push(testCase);
    });


    this.testCasesDataJson.testCaseDataArray.forEach(element => {
      var date;
      if (!element.LastExecuted.endsWith('Z')) {
        date = new Date(element.LastExecuted + 'Z');
      } else {
        date = new Date(element.LastExecuted);
      }
      this.dateString = date.toLocaleString([], { year: '2-digit', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit' });

      element.LastExecuted = this.dateString;
      this.downloadTestCaseJson.push(element);
    });
  }

  /**
   * retriggers a clone of the selected run
   */
  onRerunClicked(){
    for (var testCase of this.selectedTestCases) {
    let request = new TestRunRetriggerInput();

    request.searchId = testCase.correlationId;
    request.userEmail = this.userEmail;
    request.userName = this.userName;
    request.TestPlanInstanceId = this.testPlanInstanceId;
    request.ProjectId = this.projectID;

    this.manageTestRunsService.rerunTestCase(request).subscribe(
        (response) => {
        if (response != null) {
            this.logger.trackTrace("Rerun test case successful.");
            this.selectedTestCases = [];
            setTimeout(() => {
                this.refreshTestCaseData();   
                }, 1000);
            }
        },
        (error) => {
        this.logger.trackException(error);
        this.logger.trackTrace("Rerun test case: Some error occured.");
        }
    );
    }

  
  }


  /**
   * Starts  the next step of selected test cases
   */
  onTriggerNextStepClicked(){
    this.selectedTestCases.forEach((testCase) => {
      let request = new UpdateScenarioStepInput();
      request.correlationId = testCase.correlationId;

      //if manual step, mark as complete
      if (testCase.status.includes("User Action")) {
        request.completeCurrentStep = true;
      }

      request.stepRank = testCase.currentStepRank;
      this.manageTestRunsService.startScenarioStep(request).subscribe(
        (response) => {
          if (response != null) {
            this.logger.trackTrace("Trigger next step successful.");
          }
        },
        (error) => {
          this.logger.trackException(error);
          this.logger.trackTrace("Trigger next step : Some error occured.");
        }
      );
    });

    setTimeout(()=>{
        this.refreshTestCaseData();
        this.selectedTestCases = [];
    }, 1000);

  }
  //Downloading the gird data to excel
  downloadExcel(){
    if(this.testPlanId == 1 ){
        try {
            const testPlanName :string = this.testPlanName.replace(/ /g,"_");
            let fileName: string = "SCR_" + testPlanName + "_testcases";
            if (this.downloadTestCaseJson != null) {
                this.excelService.exportAsExcelFile(4, this.downloadTestCaseJson, fileName);
            }
        } catch (error) {
            this.logger.trackTrace(`Some error occured while exportAsExcelFile.`);
            this.logger.trackException(error);
        }
    }
    else{
        this.downloadExcelResult("", this.testPlanId);
    }

  }

    private async fetchDetailLink() {
        const promises = [];
        for (const element of this.testCaseData) {
            try{
                if (element.status != "Not Started") {
                    let detailLink: string = element.detailLink;
                    let correlationId = element.correlationId;
 
                    // detail link should be a sub path under the Web root. If its empty, or an explicit resource (starts with http: or https:), don't process.
                    if ((detailLink || "").length > 0
                      && !detailLink.toLocaleLowerCase().startsWith("http:") && !detailLink.toLocaleLowerCase().startsWith("https:")) {
                        const promise = this.testCaseProjectService.getDetailLink(detailLink).toPromise();
                        promises.push(promise);
                    }
                }
            }catch(Error){
                    continue;     
            }
            
        }

        const bb = await Promise.all(promises);
        for (const promise of bb) {
            try{
                const data = promise[0];
                const correlationId = this.testCaseData.find(x => x.testCaseIdentifier == data.testId)['correlationId'];
                this.detailLinkMap.set(correlationId, data);
            }catch(Error){
                continue;
            }
            
        }
    }

    private getTestPlanPreSteps() {
        var customSteps;
        this.testCaseProjectService.getTestPlanPreSteps(this.testPlanId).subscribe((resx: any) => {
            customSteps = resx;
            this.testCaseProjectService.getPreReqInstanceSteps(this.testPlanInstanceId).subscribe((response: any[]) => {
                if(response[0] == undefined){
                    this.preStepsData = customSteps;
                    //check of presteps data is not empty
                    if(this.preStepsData.length != 0) {
                        this.preStepsData.forEach(step => {
                            step.status = Status.NOT_STARTED;
                            step.buttonText = this.buttonText;
                            step.testPlanInstanceId = this.testPlanInstanceId;
                            delete step.testPlanId;
                            this.computeValidationStatus()
                        });
                    }
                    else {
                        this.isLoading = false;
                        if (this.testPlanInstanceId == "newTestPlanInstance") {
                            this.saveSuccess = false;
                        }
                        else{
                            this.saveSuccess = true;
                        }
                    }
                   
                }
                else{
                this.preStepsData = response;
                this.preStepsData.forEach(step => {
                    step.buttonText = "Execute";
                });
                this.computeValidationStatus();
                }
            });
        });
    }
   

    private createTestPlanPreSteps() {
        this.testCaseProjectService.getTestPlanPreSteps(this.testPlanId).subscribe((response: any) => {
            this.preStepsData = response;
            this.preStepsData.forEach(step => {
                step.status = Status.NOT_STARTED;
                step.buttonText = this.buttonText;
                step.createdAt = null;
                delete step.testPlanId;
            });
            this.preStepsData = JSON.parse(JSON.stringify(this.preStepsData));
        });

    }


    setTestCaseSchema() {
        //making this to be confgigurable by testPlanId. 
        this.httpClient
          .get<any[]>("./assets/configuration/testPlanGridConfig.json")
          .subscribe((response) => {
            let gridSchema = JSON.parse(
              response.find(
                (config) => config["testPlanId"] == this.testPlanId
              )["testCaseGridSchema"]
            );
            if (gridSchema != null) {
              this.testCaseGridSchema = gridSchema;
            }
          });
    }

    //execute functionality for presteps
    //<TODO> <refactoring needs to be done>
    executeStep(row: any) {
        this.buttonText = "Executing";
        this.preStepsData.find(
          (step) =>
            step.testPlanPreStepInstanceId == row.testPlanPreStepInstanceId
        ).buttonText = this.buttonText;
        switch (row.testPlanPreStepCode.toUpperCase()) {
          case "CR001": {           
            this.executeUploadedFile(
              row.testPlanPreStepId,
              row.testPlanPreStepInstanceId
            );
            break;
          }
          case "CR002": {
            this.assignSku(row);
            this.preStepsData = JSON.parse(JSON.stringify(this.preStepsData));
            break;
          }
          case "CR003": {
            this.postSNValidation(row);
            this.preStepsData = JSON.parse(JSON.stringify(this.preStepsData));
            break;
          }
          case "XDL001": {
            this.preStepsData = JSON.parse(JSON.stringify(this.preStepsData));
            this.executeUploadedFile(
              row.testPlanPreStepCode,
              row.testPlanPreStepInstanceId
            );
            break;
          }
          case "XDL002": {
            this.preStepsData = JSON.parse(JSON.stringify(this.preStepsData));
            this.executeUploadedFile(row.testPlanPreStepCode, null);
            break;
          }
          default:
        }
    }


    assignSku(row) {
        let request = new TestPlanPreStepInstance(row);

        this.testCaseProjectService.assignSku(request).subscribe((response) => {
            if (response != null) {
                this.assignSkuResponse = response;
                this.refreshPreStepsData();
            } else {
                this.logger.trackTrace(`Assign SKU Some error occured.`);
            }
        }, (error) => {
            this.logger.trackTrace(`Assign SKU Some error occured.`);
            this.logger.trackException(error);
        });
    }


    async postSNValidation(row: any) {
        const request = new SNValidationRequest();
        request.RequesterEmail = this.userEmail;
        request.RequesterName = this.userName;
        request.TestPlanInstanceId = this.testPlanInstanceId;
        request.TestPlanPreStepInstance = new TestPlanPreStepInstance(row);

        this.testCaseProjectService.postSNValidation(request).subscribe((response: any) => {
            this.executionResult = response;
            this.refreshPreStepsData();
        });
    }

    setCustomStepsSchema() {
        if(this.testPlanId  == 1){
            this.preStepsSchema = [
                {
                    label: 'Step',
                    class: 'col-3 col-xl-3',
                    filterType: 'string',
                    key: 'stepName',
                    isEditable: false
                },
              
    
                {
                    label: 'Status',
                    class: 'col col-xl',
                    filterType: 'status',
                    key: 'status',
                    isEditable: false,
                    columnParameters: {
                    }
                    //filterOptions: "Status"
                },
                {
                    label: 'ETA',
                    class: 'col col-xl',
                    filterType: 'date',
                    key: 'etaDateTime',
                    isEditable: false,
                    //filterOptions: "Status"
                },
                {
                    label: 'Sample Input',
                    class: 'col col-xl with-button',
                    filterType: 'download-link',
                    key: 'downloadLink',
                },
                {
                    label: 'Upload Input',
                    class: 'col col-xl with-button',
                    filterType: 'upload-link',
                    key: 'uploadLink',
                },
                {
                    label: 'Action',
                    class: 'col col-xl with-button',
                    filterType: 'custom-event',
                    key: '',
                    isEditable: false,
                    columnParameters: {
                        onCustomEventClicked: (row: any) => { this.executeStep(row); },
                    }
                },
                {
                    label: 'Download Result',
                    class: 'col col-xl with-button',
                    filterType: 'string',
                    key: 'validSKUs'
                },
                {
                    label: 'Validation Progress',
                    class: 'col col-xl with-button',
                    filterType: 'string',
                    key: 'validationStatus'
                }
            ];
        } else {
            this.preStepsSchema = [
                {
                    label: 'Step',
                    class: 'col-3 col-xl-3',
                    filterType: 'string',
                    key: 'stepName',
                    isEditable: false
                },
                {
                    label: 'Status',
                    class: 'col col-xl',
                    filterType: 'status',
                    key: 'status',
                    isEditable: false,
                    columnParameters: {
                    }
                    //filterOptions: "Status"
                },
                {
                    label: 'ETA',
                    class: 'col col-xl',
                    filterType: 'date',
                    key: 'etaDateTime',
                    isEditable: false,
                    //filterOptions: "Status"
                },
                {
                    label: 'Sample Input',
                    class: 'col col-xl with-button',
                    filterType: 'download-link',
                    key: 'downloadLink',
                },
                {
                    label: 'Upload Input',
                    class: 'col col-xl with-button',
                    filterType: 'upload-link',
                    key: 'uploadLink',
                },
                {
                    label: 'Action',
                    class: 'col col-xl with-button',
                    filterType: 'custom-event',
                    key: '',
                    isEditable: false,
                    columnParameters: {
                        onCustomEventClicked: (row: any) => { this.executeStep(row); },
                    }
                },
                {
                    label: 'Download Result',
                    class: 'col col-xl with-button',
                    filterType: 'string',
                    key: 'validSKUs'
                },
            ];
        }
       
    }


    //current validation of serial number
    getPreStepStatus(row: any, response: any) {
        switch (response.stepStatus) {
            case "Error":
                row.status = Status.FAILED;
                break;

            case "Pending":
                row.status = Status.PENDING;
                break;

            case "In Progress":
                row.status = Status.IN_PROGRESS;
                break;

            case "InProgress":
                row.status = Status.IN_PROGRESS;
                break;

            case "Complete":
                row.status = Status.COMPLETED;
                break;
            case "Completed":
                row.status = Status.COMPLETED;
                break;

            default:
                row["status"] = Status.NOT_STARTED;


        }

        this.preStepsData = JSON.parse(JSON.stringify(this.preStepsData));
    }

    get TestCaseGrid() {
        return this.testCaseData;
    }

    get TestCaseGridSchema() {
        return this.testCaseGridSchema;
    }

    get PreStepsGrid() {
        return this.preStepsData;
    }

    get PreStepsSchema() {
        return this.preStepsSchema;
    }


    //this function exists for future configuration, if there are different button labels needed. 
    getButtonText() {

        return "Execute";

    }



    onFileSelected(event: File) {
        this.selectedFile = event;
        this.openModal(this.inputFileModal);
    }


    /**
     * Executes the uploaded file in presteps for different types of files. 
     */
    private executeUploadedFile(stepCode:string, testPlanPreStepInstanceId:string) {
        const preStepIndex: number = this.preStepsData.findIndex(
          (step) => step.testPlanPreStepCode == stepCode
        );
        if (this.selectedFile == null) {
          window.alert("No file is selected. Please upload a file first.");
          this.preStepsData[preStepIndex].buttonText = "Execute";
          this.preStepsData = JSON.parse(JSON.stringify(this.preStepsData));
          return;
        }

        const fileName = this.selectedFile.name.toLowerCase();
        const isXlsFile = fileName.indexOf("xls") > 0;
        const isCsvFile = fileName.indexOf("csv") > 0;

        if (isCsvFile) {
          this.uploadSKUs();
        } else if (isXlsFile) {
          this.uploadInputToBulkUpload(testPlanPreStepInstanceId, stepCode, preStepIndex);
        }
    }

    private uploadInputToBulkUpload(testPlanPreStepInstanceId: string, stepCode: string, preStepIndex: number) {
        let reader = new FileReader();
        reader.readAsArrayBuffer(this.selectedFile);
        reader.onload = (e) => {
            let data: ArrayBuffer = reader.result as ArrayBuffer;
            const excelData = new Uint8Array(data);
            const arr = new Array();
            for (let i = 0; i < excelData.length; ++i) {
                arr[i] = String.fromCharCode(excelData[i]);
            }

            const workbook = XLSX.read(arr.join(""), { type: "binary" });
            let sheetTabName = "Input Sheet";

            let sheetJsonData = XLSX.utils.sheet_to_json(
                workbook.Sheets[sheetTabName],
                { raw: false }
            );
            let excelUploadInput = new TestRunBulkUploadInput();
            excelUploadInput.sheetJsonData = JSON.stringify(sheetJsonData);
            excelUploadInput.testPlanInstanceId = this.testPlanInstanceId;
            excelUploadInput.testPlanPreStepInstanceId =
                testPlanPreStepInstanceId;
            excelUploadInput.projectId = this.projectID;
            excelUploadInput.userEmail = this.userEmail;
            excelUploadInput.userName = this.userName;
            excelUploadInput.testRunName = sheetJsonData[0]["Scenario Name"];
            this.selectedFile = null;
            this.manageTestRunsService
                .testRunsBulkUpload(excelUploadInput)
                .subscribe(
                    (response) => {
                        if (response != null &&
                            (!response.errors || response.errors.length === 0)) {
                            if (stepCode.toUpperCase() == "XDL001") {
                                this.preStepsData[preStepIndex].status = Status.PENDING;
                            } else if (stepCode.toUpperCase() == "XDL002") {
                                this.preStepsData[preStepIndex].status = Status.COMPLETE;
                            }
                            if (this.preStepsData[preStepIndex].stepStartDate == null) {
                                this.preStepsData[preStepIndex].stepStartDate =
                                    new Date().toISOString();
                            }
                            
                            this.preStepsData[preStepIndex].buttonText = "Execute";
                            this.preStepsData = [... this.preStepsData];
                            this.storePreSteps();
                            setTimeout(() => {
                                this.refreshPreStepsData();
                                this.refreshTestCaseData();
                            }, 2000);
                        }
                    },
                    (error) => {
                        this.preStepsData[preStepIndex].buttonText = "Execute";
                        this.preStepsData = JSON.parse(
                            JSON.stringify(this.preStepsData)
                        );
                        this.logger.trackException(error),
                            this.logger.trackTrace(
                                "Some error occured: Bulk Upload Test Plan Execution:" +
                                error
                            );
                    }
                );
        };
    }

    private uploadSKUs() {
        let productRequestList = new ProductRequestList();
        productRequestList.ProductRequest = [];
        //read skus from file
        let reader = new FileReader();
        reader.readAsText(this.selectedFile);
        reader.onload = (e) => {
            let csv: any = reader.result;
            let allTextLines = [];
            allTextLines = csv.split(/\r|\n|\r/);

            const addOneSku = (sku) => new Promise((resolve) => setTimeout(
                () => resolve(
                    this.addSkuToProductRequestList(sku, productRequestList)
                ),
                100
            )
            );
            const addAllSkus = async () => {
                for (var line of allTextLines) {
                    if (line != "" && line != null) {
                        await addOneSku(line);
                    }
                }
                this.postSKUsForValidation(productRequestList);
            };

            addAllSkus();
        };
    }

    private postSKUsForValidation(productRequestList: ProductRequestList) {

        this.masterDataService.validateSku(productRequestList.ProductRequest).subscribe(async (response: string[]) => {
            if (response != null && response.length > 0) {
                await this.computeValidationStatus();
                setTimeout(()=> this.refreshPreStepsData(), 3000);
                response.forEach(element => {
                    this.logger.trackTrace(`SKU Validation for SKU:${{element}}completed Successfully`);
                });


            } else {
                this.logger.trackTrace(`SKU Validation Some error occured, sku list submitted for validation`);
            }
        }, (error) => {
            this.logger.trackTrace(`SKU Validation Some error occured, sku list submitted for validation`);
            this.logger.trackException(error)
        });
    }

    private addSkuToProductRequestList(line: any, productRequestList: ProductRequestList) {
        let productRequest = new ProductRequest();
        line = line.trim();
        productRequest.sku = line;
        productRequest.TestPlanInstanceId = this.testPlanInstanceId;
        productRequest.RequestedBy = this.userEmail; 
        if (this.country != "") {
            productRequest.country = this.country;
        }
        productRequestList.ProductRequest.push(productRequest);
        return Promise.resolve({});
    }
        
    downloadSampleInput(testPlanPreStepCode:string) {
        let fileName: string = "SampleInputFile";
        this.testCaseProjectService
            .downloadSampleInput(testPlanPreStepCode)
            .subscribe(
            (response) => {
                if (response != null) {
                if (this.testPlanId == 1) {
                    let file = new Blob([response], { type: "text/csv" });
                    saveAs(file, fileName);
                } else {
                    let file = new Blob([response], {
                    type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
                    });
                    saveAs(file, fileName);
                }
                }
            },
            (error) => {
                this.logger.trackTrace(
                `Some error occured on excel template download: ${error}`
                );
                this.logger.trackException(error);
            }
            );
    }

    downloadStepResponse(testPlanPreStepCode:string) {
        switch (testPlanPreStepCode.toUpperCase()) {
          case "CR001":
            this.downloadValidatedSkus();
            break;

          case "CR002":
            this.downloadResponseAsJSON(this.preStepsData[1].testCaseValidationResponse);
            break;

          case "CR003":
            this.downloadResponseAsJSON(this.executionResult);

          case "XDL001":
            this.downloadExcelResult(testPlanPreStepCode, 0);

          default:
            break;
        }
    }

    downloadExcelResult(testPlanPreStepCode:string, testPlanId:number) {
        let fileName = "";
        let testPlanInstanceId = this.testPlanInstanceId;
        let testPlanPreStepInstanceId = null;
        let testPlanPreStepId = 0;
        if(testPlanPreStepCode != "") {
            fileName = "OrderCreation_" + this.testPlanName;
            let prestepFound = this.preStepsData.find((x) => x.testPlanPreStepCode == testPlanPreStepCode);
            testPlanPreStepInstanceId = prestepFound.testPlanPreStepInstanceId;
            testPlanPreStepId = prestepFound.testPlanPreStepId;
        } else {
            fileName = "TestRunDetails_" + this.testPlanName;
        }
        this.testCaseProjectService
          .downloadExcel(
            testPlanId,
            testPlanPreStepId,
            testPlanInstanceId,
            testPlanPreStepInstanceId
          )
          .subscribe(
            (response: any) => {
              if (response != null) {
                let file = new Blob([response], {
                  type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
                });
                saveAs(file, fileName);
              }
            },
            (error) => {
              this.logger.trackTrace(
                `Some error occured on prestep excel result download: ${error}`
              );
              this.logger.trackException(error);
            }
          );    
    }

    downloadValidatedSkus() {
        let skusData = [];
        this.testCaseProjectService.getValidatedSkus(this.testPlanInstanceId).subscribe((response: any) => {
            skusData = response;
            this.computeValidationHelper(skusData);
            const a = document.createElement("a");
            a.href = URL.createObjectURL(new Blob([JSON.stringify(skusData, null, 2)], {
                type: "text/plain"
            }));
            a.setAttribute("download", "validatedSKUs.txt");
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
        });
    }

    downloadResponseAsJSON(response:any){
        const a = document.createElement("a");
        a.href = URL.createObjectURL(new Blob([JSON.stringify(response, null, 2)], {
            type: "text/plain"
        }));
        a.setAttribute("download", "response.txt");
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
    }
    
    async computeValidationStatus() {
        this.isLoading = false;
        if (this.testPlanInstanceId == "newTestPlanInstance") {
            this.saveSuccess = false;
        }
        else{
            this.saveSuccess = true;
        }
        this.testCaseProjectService.getValidatedSkus(this.testPlanInstanceId).subscribe(async (response: any) => {
            var skusData = response;
            const waitForValidation = (skusData)=> new Promise((resolve) => resolve(this.computeValidationHelper(skusData)));
            await waitForValidation(skusData);
          
        });
    }

    computeValidationHelper(skusData: any) {
        var completed: number = 0;
        skusData.forEach(element => {
            if (element.validationStatus == "Completed") {
                completed = completed + 1;
            }
        });

        this.validationStatus = completed.toString() + "/" + skusData.length.toString();

        if (completed == skusData.length && completed != 0 && this.testPlanId == 1) {
            this.preStepsData[0].status = Status.COMPLETED;
            if(this.preStepsData[0].stepCompleteDate == null) {
                this.preStepsData[0].stepCompleteDate = new Date().toISOString();
            }
        }
        else if (skusData.length != 0 && this.testPlanId == 1) {
            this.preStepsData[0].status = Status.IN_PROGRESS;
            
            if(this.preStepsData[0].stepStartDate == null) {
                this.preStepsData[0].stepStartDate = new Date().toISOString();
            }
        }
        else if(this.testPlanId == 1){
            this.preStepsData[0].status = Status.NOT_STARTED;
        }
        this.storePreSteps();
        this.preStepsData = JSON.parse(JSON.stringify(this.preStepsData));
        return Promise.resolve({});
    }

    onSaveTestPlanInstance() {

        if (this.testPlanInstance != undefined) {
            this.saveTestPlanInstance();
        }

    }

    private saveTestPlanInstance() {        
        this.manageTestRunsService.ngxLoaderConfig.text = 'Test Plan Saving Please Wait.....';
        this.ngxLoader.start();
        let request = new TestPlanInstanceRequest();
        this.saveButtonDisabled = true;
        this.testPlanInstance.countryCode = this.country;
        this.testPlanInstance.stateCode = this.stateCode;
        this.testPlanInstance.status = this.testPlanStatus;
        this.testPlanInstance.customerName = this.partnerName;
        request.testPlanInstance = this.testPlanInstance;
        this.testCaseProjectService.storeTestPlanInstance(request).subscribe((response: any) => {
            if (response != null) {
                if (response != this.testPlanInstanceId && response != "") {
                    this.preStepsData.forEach(step => {
                        step.testPlanInstanceId = response;
                    });
                    this.storePreSteps();
                }
                    this.saveButtonDisabled = false;                    
                    this.ngxLoader.stop();
                    if (this.testPlanInstanceId == "newTestPlanInstance") {
                        this.saveSuccess = false;
                    }
                    else{
                        this.saveSuccess = true;
                    }
                    this.openModal(this.saveModal);
                    this.logger.trackTrace(`Test Plan Instance stored succesfully!`);
                    this.router.navigate(['/test-case-view', {'org':this.organizationId, 'projectId': this.projectID, 'testPlanId':this.testPlanId, 'testPlanInstanceId':response}]);
            } else {
                this.saveButtonDisabled = false;
                this.logger.trackTrace(`Store Test Plan Instance Some error occured.`);
            }
        }, (error) => {            
            this.saveButtonDisabled = false;
            this.logger.trackTrace(`Store Test Plan Instance Some error occured: ${error}`);
            this.logger.trackException(error);
        });
    }

    private updateTestCasePartnerName() {
        this.testCaseData.forEach(testCase => {
            var inputParameterObject = JSON.parse(testCase.inputParameter);
            if (inputParameterObject.partnerName != this.partnerName) {
                inputParameterObject.partnerName = this.partnerName;
                testCase.inputParameter = JSON.stringify(inputParameterObject);
                this.saveTestCaseInstance(testCase);
            }
        });

        this.testCaseData = JSON.parse(JSON.stringify(this.testCaseData));

    }

    updateInputParameters(event: any[]) {
        const correlationId = event[0];
        const inputParameters = event[1];
        this.testCaseData.forEach(element => {
            if (element.correlationId == correlationId) {
                element.inputParameter = inputParameters;
                this.saveTestCaseInstance(element);
                return;
            }
        });
    }

    private openModal(content: any) {
        this.modalService.open(content).result.then( (result) => { 
            console.log("Closed with "+ result);   
             this.refreshPreStepsData();
        },
        (reason) =>{
            console.log("Dismissed");
            this.refreshPreStepsData();
            this.selectedFile = null;
        });
    }

    saveTestCaseInstance(testCaseInstance) {
        let inputParameter: string = testCaseInstance.inputParameter;
        testCaseInstance.inputParameter = inputParameter.replace(/\\/g, '');
        let request = new TestCaseInstanceRequest();
        request.testCaseInstances = [testCaseInstance];
        this.testCaseProjectService.storeTestCaseInstance(request).subscribe((response: any) => {
            if (response != null && response.length > 0) {
                this.logger.trackTrace(`Test Case Instance stored succesfully!`)
            } else {
                this.logger.trackTrace(`Store Test Case Instance Some error occured.`);
            }
        }, (error) => {
            this.logger.trackTrace(`Store Test Case Instance Some error occured: ${error}`);
            this.logger.trackException(error)
        });

    }
    
    onValidationClicked() {
        let request = new UpdateScenarioStepInput();
        this.selectedTestCases.forEach(testCase => {

            request.correlationId = testCase.correlationId;
            request.stepRank = 1;
            this.testCaseProjectService.triggerValidation(request).subscribe((response: any) => {
                if (response != null && response == true) {
                    this.testCaseData.forEach(element => {
                        if (element.correlationId == testCase.correlationId) {
                            element.status = Status.START;
                        }
                    });

                    this.testCaseData = JSON.parse(JSON.stringify(this.testCaseData));
                    this.logger.trackTrace(`Triggered validation succesfully!`);
                } else {
                    this.logger.trackTrace(`Trigger validation some error occured`);
                }
            }, (error) => {
                this.logger.trackTrace(`Trigger Validaton Some error occured:${error}`);
                this.logger.trackException(error);
            });
        });
        this.selectedTestCases = [];
    }

    onTestCaseSelectionChanged(rowsSelected: any[]) {
        this.selectedTestCases = rowsSelected || [];
    }

    //creates new test plan instance 
    private createNewTestPlanInstance() {
        this.testPlanInstance = new TestPlanInstance();
        this.testPlanInstance.TestCaseInstances = this.testPlanId == 1 ? this.testCaseData : [];
        this.testPlanInstance.testPlanID = this.testPlanId;
        this.testPlanCreatedOn = new Date().toISOString();
        this.testPlanCompletedBy = new Date().toISOString();
        this.testPlanStartOn = new Date().toISOString();

        //TODO <rozerinakkus> <02-02-2022> How these following fields shoud be filled, or if it shoudl be editable from UI needs to be discussed.

        this.testPlanInstance.TestPlanDescription = "";
        this.testPlanInstance.CustomerName = "";
        this.testPlanInstance.CustomerType = "";
        this.testPlanInstance.createDate = new Date().toISOString();
        this.testPlanInstance.startDate = new Date().toISOString();
        this.testPlanInstance.endDate = new Date().toISOString();
        this.testPlanInstance.owner = this.userName.trim();
        this.isLoading = false;
        if (this.testPlanInstanceId == "newTestPlanInstance") {
            this.saveSuccess = false;
        }
        else{
            this.saveSuccess = true;
        }
    }

    //creates 1 new test case intance given a test case template
    createNewTestCaseInstance(testCase: any) {
        let newTestCaseInstance = new TestCaseInstance();
        let keys = Object.keys(testCase);
        keys.forEach(key => {
            if (newTestCaseInstance.hasOwnProperty(key)) {
                newTestCaseInstance[key] = testCase[key];
            }
        });


        newTestCaseInstance.testCaseId = testCase.testCaseID;
        newTestCaseInstance.userEmail = this.userEmail;
        newTestCaseInstance.userName = this.userName.trim();
        newTestCaseInstance.projectId = this.projectID;

        //correlationID will be returned after saving. 
        newTestCaseInstance.correlationId = "";

        //TODO: <rozerinakkus> <02-02-2022> The status logic should be discussed, this assignment could be wrong.
        newTestCaseInstance.status = Status.NOT_STARTED;

        //TODO: <rozerinakkus> 24 Jan 2022 <Maybe format this date in UTC> 

        newTestCaseInstance.createDate = new Date().toISOString();

        //TODO: <rozerinakkus> <02-02-2022> endDate and Startdate should be discussed. 
        newTestCaseInstance.startDate = new Date().toISOString();
        newTestCaseInstance.endDate = new Date().toISOString();
        newTestCaseInstance.TestCaseDescription = "";
        this.testCaseData.push(newTestCaseInstance);
    }

    //Creates new test case *instances* from the test cases template
    createNewTestCaseInstances(testCases: any[]) {
        this.isLoading = true;
        testCases.forEach(testCase => { (this.createNewTestCaseInstance(testCase)) });

        setTimeout(() => {
            this.createNewTestPlanInstance();

        },
            1000);

    }


    //Gets test cases *template* from project view, for a specific test Plan in that project (unique testPlanID) 
    getTestCasesFromProject() {
        this.testCaseProjectService.getProjectTestCaseView(this.projectID).subscribe((response: any) => {
            this.projectID = response[0].projectID;
            this.testPlanProjectName = response[0].projectName;
            this.testPlan = response[0].testPlans.find(testPlan => testPlan.testPlanID == this.testPlanId);
            const testCases: any[] = response[0].testPlans.find(testPlan => testPlan.testPlanID == this.testPlanId).testCases;
            this.createNewTestCaseInstances(testCases);
        }, (error:any)=>{
            this.logger.trackTrace(`Error in Get Test Cases in Test Plan Instance View: ${error}`);
            this.logger.trackException(error);
        });
    }

    refreshTestCaseData() {
        //this.isLoading = true;
        this.testCaseProjectService.getTestPlanInstance(this.testPlanInstanceId).subscribe((response: any) => {
            this.testPlanInstance = response;
            this.testPlanName = response.testPlanName;
            this.testPlanStatus = response.status;
            this.testPlanCreatedOn = response.createDate;
            this.testPlanOwner = response.owner;
            this.testPlanStartOn = response.startDate;
            this.testPlanCompletedBy = response.endDate;
            this.testPlanLastModified = response.modifiedDate;
            this.testPlanId = response.testPlanID;
            if(response.testCaseInstances.length > 0 && response.testCaseInstances[0].correlationId != ""){ 
                this.testCaseData = response.testCaseInstances;
            }
            else{
                this.testCaseData = [];
            }
            if(this.testPlanId != 1 ){
                this.testCaseData = this.testCaseData.filter(x=>x.testPlanPreStepInstanceId == null || x.testPlanPreStepInstanceId == undefined);
                for(var i = 0; i<this.testCaseData.length; i++){
                    var filteredSteps:any[] = this.testCaseData[i].stepRunDetails.filter(x=> x.status != "");
                    var curStep = filteredSteps.pop();
                    const currentStep = curStep.componentName;
                    if(currentStep.includes("Manual")){
                        if(this.testCaseData[i].currentStepStatus == "Complete"){
                            this.testCaseData[i].status = "Complete";
                        }
                        else{
                           this.testCaseData[i].status = "Pending User Action";
                        }
                    }else{
                        this.testCaseData[i].status = curStep.status;
                    }
                    this.testCaseData[i].currentStepRank = curStep.stepRank;
                    this.testCaseData[i].currentStep = currentStep;
                }
            }
            this.fetchDetailLink();
            this.testCaseData = JSON.parse(JSON.stringify(this.testCaseData));
        }, (error:any)=>{
            this.logger.trackTrace(`Error in Get Test Case Instances in Test Plan Instance View: ${error}`);
            this.logger.trackException(error);
        });

    }

    refreshPreStepsData() {
        this.getTestPlanPreSteps();  
    }


    storePreSteps() {
        var request = JSON.parse(JSON.stringify(this.preStepsData));
        request.forEach(step => {
            delete step.buttonText;
            delete step.stepName;
            delete step.stepDescription;
        });
        this.testCaseProjectService.storePreReqSteps(request).subscribe((response: any[]) => {

        },(error)=>{
            this.logger.trackTrace(`Some error occured on storing pre-steps. ${error}`)
            this.logger.trackException(error);
        });
    }

    public exportAsExcelFile(): void {
        let fileName:string = "CommercialReporting-"+this.testPlanInstanceId;
        this.testCaseProjectService.populateExcel(this.testPlanInstanceId).subscribe((response)=>{
            if(response != null){
               let file = new Blob([response], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
               saveAs(file, fileName); 
            }
            
        },(error) => {
            this.logger.trackTrace(`Some error occured on excel template download: ${error}`)
            this.logger.trackException(error);
        });

    
        this.testCaseProjectService.getEmailBody().subscribe((response) =>{
            if(response != null){
                var emailBody = response;
                this.emailBody = emailBody.replace("<filename>", fileName+".xlsx");
                window.location.href = "mailto:"+""+"?subject="+"Commercial Reporting Excel"+"&body="+this.emailBody;
            }
        },(error) => {
            this.logger.trackTrace(`Some error occured on email body download: ${error}`)
            this.logger.trackException(error);
        });
    }

    getUrlParamsForProject(){
      return {
        "org": this.organizationId,
        "projectId":this.projectID,
      }
    }
}

